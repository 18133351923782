// Here you can add other styles
// font style body and table
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
body,
.table {
  font-family: 'Tajawal', sans-serif !important;
}
// navbar sidebar background color
.sidebar,
.sidebar-brand,
.sidebar-toggler {
  background-color: #faf9f6;
}
// navbar sidebar text color
// .sidebar-nav .nav-link {
//   color: black;
// }
// main area background color
.bg-light {
  background-color: #f0f4f7 !important;
}
// header and footer background color
.header,
.footer {
  background-color: #faf9f6 !important;
}
// sidebar active nav link
// .sidebar-nav .nav-link.active {
//   background: #247ba0 !important;
// }
// link styles
a {
  color: #3899ec;
}
// scrollbar style
::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: #f7f5f5; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

// customise datepicker clear button
.react-datepicker__close-icon {position:static !important;}

.form-label{
  font-weight: 700;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.link {
  color: #999;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}
.link:hover {
  color: #23263c;
}
.activeCategoryLink {
  color: #23263c;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
}


.tabs-item{
  background-color: rgb(187, 187, 187);

}
.tabs-item {
  background-color: rgb(187, 187, 187);
  color: #8298bc;
  cursor: pointer;
}

.disable-tab {
  background-color: rgb(7, 43, 71);
  color: #918f8f;
}

.mainContainer{
  margin: 20px 150px  ;
  // marginTop:"000px"
}
.scrollable{
  max-width: 100%;
  // max-height: 40%;
  overflow-x: scroll;
  // overflow-y: scroll;
}
.editableTable {
  width: 100%;
  border-spacing: 0;
  border: 1px solid black;
  background-color: transparent;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
// input[type=number].no-spinner::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
// -webkit-appearance: none;
//  margin: 0;
// }
.sidebar-color {
  background-color: #faf9f6 !important;
}

.sidebar-nav .nav-group.show {
  background: #eff2f6 !important;
  border-radius: 15px;
  width: 90%;
  margin-right: 12px;
}

.sidebar-nav .nav-group {
  position: relative;
  // transition: background 0.15s ease-in-out;
  width: 90%;
  margin-left: 12px;
}
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 20px;
}
.sidebar-nav .nav-link {
  display: flex;
  flex: 1 1;
  align-items: center;
  width: 100%;
  padding: 1;
  color: var(--cui-sidebar-nav-link-color, rgb(86, 106, 127));
  text-decoration: none;
  white-space: nowrap;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: var(--cui-sidebar-nav-link-bg, transparent);

  // transition: background 0.15s ease, color 0.15s ease;
}
.sidebar-nav .nav-link:hover {
  color: var(--cui-sidebar-nav-link-color,rgb(86, 106, 127));
  background-color: #eff2f6;
}
.sidebar-nav .nav-link.active {
  color: #247ba0 !important;
  font-weight: bold;
  border-right: 3px solid;
  width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background: #eff2f6;
}
.sidebar-nav .nav-group.show > .nav-group-toggle::after {
  transform: rotate(90deg);
}
.sidebar-nav .nav-group-toggle::after {
  display: block;
  flex: 0 1 8px;
  height: 8px;
  margin-bottom: 13px;
  content: "⮞";
  transition: transform 0.15s;
  color: #5b779a;
}
.sidebar-nav .nav-group-toggle {
  cursor: pointer;
  font-size: 18px;
}
.nav-link-without-icon {
  padding-left: 3rem;
}
.nav-link-without-icon {
  padding-left: 3rem;
  color: #5b779a;
}
.navlink-icon {
  width: 25;
  height: 22;
  margin-right: 1.5rem;
}

.nav-link-without-icon {
  padding-left: 1rem;
}
.nav-link span{
  color:  #5b779a;
}

.datepicker-input {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
}

//calendar
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 color: #5b779a;
 border-radius: 6px;
}
.react-calendar__tile--now {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #5b779a;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #5b779a;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #5b779a;
 border-radius: 6px;
 font-weight: bold;
 color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #5b779a;
 color: white;
}

@media print {
  body {
    direction: rtl; /* Set the print direction to right-to-left (RTL) */
  }
}

/* FileUploader.css */

.file-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.file-input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.file-input {
  display: none;
}

.selected-file {
  margin-top: 15px;
  font-size: 14px;
}

.selected-file button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selected-file button:hover {
  background-color: #45a049;
}

.custom-datepicker {
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  height: 100%;

  /* Modify other styles as needed */
}


.loaderLoding {
  width: 82px;
  height: 18px;
  position: relative;
}
.loaderLoding::before , .loaderLoding::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50% , 10%);
  top: 0;
  background: #321fdb;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  animation: jump 0.5s ease-in infinite alternate;
}

.loaderLoding::after {
  background: #0000;
  color: rgba(44, 56, 74, 0.95);
  top: 100%;
  box-shadow: 32px -20px , -32px -20px;
  animation: split 0.5s ease-out infinite alternate;
}

@keyframes split {
  0% { box-shadow: 8px -20px, -8px -20px}
  100% { box-shadow: 32px -20px , -32px -20px}
}
@keyframes jump {
 0% { transform: translate(-50% , -150%)}
 100% { transform: translate(-50% , 10%)}
}

